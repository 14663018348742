import { TableRow, TableCell, Typography } from '@mui/material'
import { ReactNode } from 'react'

const padding = '8px 16px'

interface Props {
  columns: ReactNode[]
  isHeader?: true
  noWrapRules?: boolean[]
}

export function CommonTableRow({ columns, isHeader, noWrapRules }: Props) {
  return (
    <TableRow>
      {columns.map((column, index) => {
        return (
          <TableCell
            key={index}
            sx={{
              padding: padding,
              whiteSpace: noWrapRules && noWrapRules[index] ? 'nowrap' : 'pre-wrap',
              textAlign: 'right',
            }}
          >
            {typeof column === 'string' || typeof column === 'number' || typeof column === 'boolean' ? (
              <Typography variant={isHeader ? 'smallDarkHeader' : 'darkText'}>{column}</Typography>
            ) : (
              <>{column}</>
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
}
