import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { getFullUrl } from './getFullUrl'

export async function updateDeviceFirmwares(
  token: string | null,
  product: typeof CITY_CONNECT_PRODUCT,
  version: string,
): Promise<boolean> {
  const url = getFullUrl(`/api/devices/firmware/${product}`)
  const requestOptions = {
    method: 'PUT',
    body: version,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const result = await fetch(url, requestOptions)
  if (!result.ok) {
    console.log(`result: ${result.status}`)
    return false
  }
  return true
}

export interface DefaultFirmwareInfoData {
  version: string | null
}
