import useSWR, { SWRResponse } from 'swr'
import { ProductInfo } from '../common-code-react/common-types/productInfo'
import { CAO_PRODUCT } from '../common-code-react/common-types/products/cao'
import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { fetcher } from '../common-code-react/utils/fetcher'
import { getFullUrl } from '../utils/getFullUrl'

export function useProductInfo(
  token: string | null,
  product: typeof CITY_CONNECT_PRODUCT | typeof CAO_PRODUCT,
): SWRResponse<ProductInfo> {
  const url = getFullUrl(`/api/products/${product}/`)
  return token ? useSWR<ProductInfo>(url, fetcher(token)) : useSWR(null)
}
