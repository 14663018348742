import { ProductInfo } from '../common-code-react/common-types/productInfo'
import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { getFullUrl } from './getFullUrl'

export async function updateProductInfo(
  token: string | null,
  product: typeof CITY_CONNECT_PRODUCT,
  body: ProductInfo,
): Promise<boolean> {
  const url = getFullUrl(`/api/products/${product}`)
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const result = await fetch(url, requestOptions)
  if (!result.ok) {
    console.log(`result: ${result.status}`)
    return false
  }
  return true
}
