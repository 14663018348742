import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from '@mui/material'
import { ReactNode, useState } from 'react'

interface Props {
  buttonText: string
  buttonIcon?: JSX.Element
  buttonTooltip: string
  isDisabled?: boolean
  dialogTitle: string
  dialogContent: ReactNode
  dialogConfirmText: string
  dialogConfirmAction: () => void
}

export function ButtonWithDialog({
  buttonText,
  buttonIcon,
  buttonTooltip,
  isDisabled,
  dialogTitle,
  dialogContent,
  dialogConfirmText,
  dialogConfirmAction,
}: Props) {
  const [openDialog, setOpenDialog] = useState(false)

  const handleClickOpen = () => {
    setOpenDialog(true)
  }

  return (
    <>
      <Tooltip title={buttonTooltip}>
        <span>
          <Button variant='outlined' disabled={isDisabled} onClick={handleClickOpen} startIcon={buttonIcon}>
            {buttonText}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContent>{dialogContent}</DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dialogConfirmAction()
              setOpenDialog(false)
            }}
            variant='outlined'
          >
            {dialogConfirmText}
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false)
            }}
            variant='contained'
            disableElevation
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
