import { Container, Stack, Table, TableBody, TableContainer, TableHead, CircularProgress } from '@mui/material'
import { useContext } from 'react'
import { LoginContext } from '../../Login'
import { ButtonWithDialog } from '../common/ButtonWithDialog'
import { CommonTableRow } from '../common/CommonTableRow'
import { UploadFirmware } from './UploadFirmware'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import { deleteFirmware } from '../../utils/deleteFirmware'
import { updateDeviceFirmwares } from '../../utils/updateDeviceFirmwares'
import { DefaultFirmware } from './DefaultFirmware'
import { CITY_CONNECT_PRODUCT } from '../../common-code-react/common-types/products/cityConnect'
import { useFirmwareInfos } from '../../hooks/useFirmwareInfos'

export function CityConnectFirmwares() {
  const loginContext = useContext(LoginContext)

  const {
    data: firmwareInfos,
    error: firmwareInfosError,
    mutate: firmwareInfosMutate,
  } = useFirmwareInfos(loginContext.accessToken, CITY_CONNECT_PRODUCT)

  async function handleDelete(version: string): Promise<void> {
    console.log(`deleting ${version}`)
    if (loginContext && loginContext.accessToken) {
      const result = await deleteFirmware(loginContext.accessToken, CITY_CONNECT_PRODUCT, version)
      if (result) {
        await firmwareInfosMutate()
      }
    }
  }

  async function handleSetForAll(version: string): Promise<void> {
    console.log(`deleting ${version}`)
    await updateDeviceFirmwares(loginContext.accessToken, CITY_CONNECT_PRODUCT, version)
  }

  function deleteButton(version: string) {
    return (
      <ButtonWithDialog
        buttonText='Delete'
        buttonIcon={<DeleteForeverIcon />}
        buttonTooltip={`Delete ${version}`}
        dialogTitle={`Confirm delete ${version}`}
        dialogContent={`This will delete the firmware ${version} from the storage account and devices will no longer be able to reach the file. Are you sure?`}
        dialogConfirmText='Delete'
        dialogConfirmAction={() => handleDelete(version)}
      />
    )
  }

  function setForAllDevicesButton(version: string) {
    return (
      <ButtonWithDialog
        buttonText='Set for all'
        buttonIcon={<UpgradeIcon />}
        buttonTooltip={`Delete ${version}`}
        dialogTitle={`Confirm set ${version} for all devices`}
        dialogContent={`This will set the desired firmware to ${version} for all the devices. Do you want to proceed?`}
        dialogConfirmText='Yes'
        dialogConfirmAction={() => handleSetForAll(version)}
      />
    )
  }

  return (
    <Container maxWidth='xl'>
      <Stack direction='column' spacing={4}>
        {firmwareInfos && (
          <>
            <TableContainer sx={{ maxWidth: 600 }}>
              <Table sx={{ minWidth: 480 }} aria-label='City-M Firmwares' stickyHeader>
                <TableHead>
                  <CommonTableRow isHeader columns={['Firmware version', '', '']} />
                </TableHead>
                <TableBody>
                  {firmwareInfos.map((firmwareInfo, index) => (
                    <CommonTableRow
                      key={index}
                      columns={[
                        firmwareInfo.version,
                        deleteButton(firmwareInfo.version),
                        setForAllDevicesButton(firmwareInfo.version),
                      ]}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <DefaultFirmware firmwares={firmwareInfos.map((firmwareInfo) => firmwareInfo.version)} />
            <UploadFirmware
              existingVersions={firmwareInfos.map((fi) => fi.version)}
              uploadDone={() => {
                firmwareInfosMutate()
              }}
            />
          </>
        )}
        {!firmwareInfos && !firmwareInfosError && <CircularProgress />}
      </Stack>
    </Container>
  )
}
