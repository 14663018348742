import { useContext, useRef, useState } from 'react'
import { Box, Button, Input, TextField, Typography } from '@mui/material'
import { uploadFirmware } from '../../utils/uploadFirmware'
import { LoginContext } from '../../Login'
import { CITY_CONNECT_PRODUCT } from '../../common-code-react/common-types/products/cityConnect'

interface Props {
  existingVersions: string[]
  uploadDone: () => void
}

export function UploadFirmware({ existingVersions, uploadDone }: Props) {
  const loginContext = useContext(LoginContext)

  const [file, setFile] = useState<File>()
  const [version, setVersion] = useState<string>('')
  const [syncing, setSyncing] = useState<boolean>(false)

  const fileInputRef = useRef<HTMLInputElement>()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '400px' }}>
      <Typography variant='mediumDarkHeader'>Upload New Firmware</Typography>
      <TextField
        id='version'
        label='Version'
        variant='standard'
        value={version}
        onChange={(e) => setVersion(e.target.value)}
      />
      <Input
        ref={fileInputRef}
        type='file'
        name='upload-firmware'
        onChange={(e) => {
          const target = e.target as HTMLInputElement
          if (target.files && target.files[0]) {
            const file = target.files[0]
            setFile(file)
          }
        }}
      />
      <Button
        variant='contained'
        disabled={!file || !version || version === '' || existingVersions.includes(version) || syncing}
        onClick={async () => {
          if (version && file) {
            setSyncing(true)
            if (loginContext && loginContext.accessToken) {
              await uploadFirmware(loginContext.accessToken, CITY_CONNECT_PRODUCT, version, file)
            }

            setSyncing(false)
            setVersion('')
            if (fileInputRef && fileInputRef.current) {
              fileInputRef.current.value = ''
            }
            uploadDone()
          }
        }}
      >
        {file ? `Upload - ${file.name.toLowerCase()}` : 'Upload'}
      </Button>
    </Box>
  )
}
