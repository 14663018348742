import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { getFullUrl } from './getFullUrl'

export async function deleteFirmware(
  token: string,
  product: typeof CITY_CONNECT_PRODUCT,
  version: string,
): Promise<boolean> {
  try {
    // 👇 Uploading the file using the fetch API to the server
    const result = await fetch(getFullUrl(`/api/firmwares/${product}/${version}`), {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!result.ok) {
      return false
    }
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
