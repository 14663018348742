import { useContext, useEffect, useState } from 'react'
import { Box, IconButton, Link, MenuItem, Select, Typography } from '@mui/material'
import { LoginContext } from '../../Login'
import { updateCode } from '../../utils/updateCode'
import { deleteDashboardCode } from '../../utils/deleteDashboardCode'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import RestoreIcon from '@mui/icons-material/Restore'
import { CommonTableRow } from '../common/CommonTableRow'
import { ButtonWithDialog } from '../common/ButtonWithDialog'
import { updateDeviceSettingsInfo } from '../../utils/updateDeviceSettingsInfo'
import { FirmwareInfo } from '../../common-code-react/common-types/modules/firmwareInfo'
import { CITY_CONNECT_PRODUCT } from '../../common-code-react/common-types/products/cityConnect'
import { DeviceReportInfo } from '../../common-code-react/common-types/device/deviceReport'
import { useDeviceSettingsInfo } from '../../hooks/useDeviceSettingsInfo'

const NO_FIRMWARE_VALUE = '[NOT-SET]'

interface Props {
  deviceReportInfo: DeviceReportInfo
  firmwareInfos: FirmwareInfo[]
}

export function CityConnectDevice({ deviceReportInfo, firmwareInfos: deviceFirmwareInfos }: Props) {
  const loginContext = useContext(LoginContext)

  const { data: deviceSettingsInfo, mutate: deviceSettingsInfoMutate } = useDeviceSettingsInfo(
    loginContext.accessToken,
    CITY_CONNECT_PRODUCT,
    deviceReportInfo.deviceId,
  )

  const firmwareVersions = deviceFirmwareInfos.map((firmwareInfo) => firmwareInfo.version)
  const [selectedFirmware, setSelectedFirmware] = useState<string>(NO_FIRMWARE_VALUE)

  useEffect(() => {
    setSelectedFirmware(deviceSettingsInfo?.deviceSettings?.firmwareInfo?.version || NO_FIRMWARE_VALUE)
  }, [deviceSettingsInfo])

  function getReplaceCodeFunction(deviceId: string): () => Promise<void> {
    return async () => {
      const result = await updateCode(loginContext.accessToken, CITY_CONNECT_PRODUCT, deviceId)
      if (result) {
        deviceSettingsInfoMutate()
      } else {
        alert('Failed to update code.')
      }
    }
  }

  function getRevertCodeFunction(deviceId: string): () => Promise<void> {
    return async () => {
      const result = await deleteDashboardCode(loginContext.accessToken, CITY_CONNECT_PRODUCT, deviceId)
      if (result) {
        deviceSettingsInfoMutate()
      } else {
        alert('Failed to revert code.')
      }
    }
  }

  async function updateFirmware(newFirmwareVersion: string): Promise<void> {
    if (deviceSettingsInfo) {
      const newDeviceSettings = {
        ...deviceSettingsInfo.deviceSettings,
        firmwareInfo: {
          version: newFirmwareVersion,
        },
      }

      const result = await updateDeviceSettingsInfo(
        loginContext.accessToken,
        CITY_CONNECT_PRODUCT,
        deviceReportInfo.deviceId,
        newDeviceSettings,
      )
      if (result) {
        deviceSettingsInfoMutate(
          {
            ...deviceSettingsInfo,
            deviceSettings: newDeviceSettings,
          },
          { revalidate: false },
        )
      }
    }
  }

  const replaceButton = (
    <ButtonWithDialog
      buttonText='Replace'
      buttonTooltip='Replace with new url'
      dialogTitle='Confim action'
      dialogContent='If the url is replaced, anyone who tries to access the dashboard with the current url will fail. Do you still want to proceed?'
      dialogConfirmText='Yes'
      dialogConfirmAction={getReplaceCodeFunction(deviceReportInfo.deviceId)}
    />
  )

  const revertButton = (
    <ButtonWithDialog
      buttonText='Revert'
      buttonIcon={<RestoreIcon />}
      buttonTooltip={
        deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo &&
        deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo.oldCodes.length > 0
          ? 'Revert to the last used url'
          : 'No previous urls'
      }
      isDisabled={
        deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo &&
        deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo.oldCodes.length === 0
      }
      dialogTitle='Confim action'
      dialogContent='After reverting to a previously used url, anyone who tries to access the dashboard with the current url will fail. Do you still want to proceed?'
      dialogConfirmText='Yes'
      dialogConfirmAction={getRevertCodeFunction(deviceReportInfo.deviceId)}
    />
  )

  const dashboardUrl = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Link
        href={`${process.env.REACT_APP_CITY_CONNECT_DASHBOARD_URL}?dc=${deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo?.dashboardCode}`}
        target='_blank'
        underline='hover'
        sx={{ fontStyle: 'italic' }}
      >
        {`${process.env.REACT_APP_CITY_CONNECT_DASHBOARD_URL}?dc=${deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo?.dashboardCode}`}
      </Link>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(
            `${process.env.REACT_APP_CITY_CONNECT_DASHBOARD_URL}?dc=${deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo?.dashboardCode}`,
          )
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  )

  const diseredFirmwareComponent = (
    <Select
      labelId='select-firmware-label'
      id='select-firmware'
      value={selectedFirmware}
      label='Desired firmware'
      onChange={(e) => setSelectedFirmware(e.target.value)}
    >
      <MenuItem value={NO_FIRMWARE_VALUE}>{NO_FIRMWARE_VALUE}</MenuItem>
      {firmwareVersions.map((firmwareVersion) => (
        <MenuItem key={firmwareVersion} value={firmwareVersion}>
          {firmwareVersion}
        </MenuItem>
      ))}
    </Select>
  )

  const updateFirmwareButton = (
    <ButtonWithDialog
      buttonText='Update firmware'
      buttonTooltip={'Confirm new firmware value'}
      isDisabled={
        selectedFirmware === NO_FIRMWARE_VALUE ||
        selectedFirmware === deviceSettingsInfo?.deviceSettings?.firmwareInfo?.version ||
        !selectedFirmware
      }
      dialogTitle='Confim action'
      dialogContent='Confirm new firmware value?'
      dialogConfirmText='Confirm'
      dialogConfirmAction={() => {
        if (selectedFirmware) {
          updateFirmware(selectedFirmware)
        }
      }}
    />
  )

  const reportedFirmwareComponent = (
    <Typography variant='darkText'>{deviceReportInfo.deviceReport?.firmwareInfo?.version || 'N/A'}</Typography>
  )

  return (
    <CommonTableRow
      columns={[
        deviceSettingsInfo?.deviceId || '',
        deviceSettingsInfo?.deviceSettings?.dashboardCodeInfo?.dashboardCode || 'N/A',
        dashboardUrl,
        replaceButton,
        revertButton,
        diseredFirmwareComponent,
        updateFirmwareButton,
        reportedFirmwareComponent,
      ]}
      noWrapRules={[true, true, false, true, true, true, true]}
    />
  )
}
