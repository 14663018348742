import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { getFullUrl } from './getFullUrl'

export async function updateConstants(token: string | null, product: typeof CITY_CONNECT_PRODUCT): Promise<boolean> {
  const url = getFullUrl(`/api/updateconstants/${product}`)
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const result = await fetch(url, requestOptions)
  if (!result.ok) {
    console.log(`result: ${result.status}`)
    return false
  }
  return true
}
