import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, Toolbar, Typography } from '@mui/material'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import MemoryIcon from '@mui/icons-material/Memory'

interface Props {
  cityConnectDevicesRoute: string
  cityConnectFirmwaresRoute: string
  airImageDevicesRoute: string
}

export function Menu({ cityConnectDevicesRoute, cityConnectFirmwaresRoute, airImageDevicesRoute }: Props) {
  return (
    <Drawer
      sx={{
        width: '245px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '245px',
          boxSizing: 'border-box',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Toolbar />
      <List>
        <ListItem disablePadding>
          <ListItemButton component='a' href={cityConnectDevicesRoute}>
            <ListItemIcon>
              <DeviceHubIcon />
            </ListItemIcon>
            <Typography variant='smallDarkHeader'>City Connect Devices</Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component='a' href={cityConnectFirmwaresRoute}>
            <ListItemIcon>
              <MemoryIcon />
            </ListItemIcon>
            <Typography variant='smallDarkHeader'>City Connect Firmwares</Typography>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton component='a' href={airImageDevicesRoute}>
            <ListItemIcon>
              <DeviceHubIcon />
            </ListItemIcon>
            <Typography variant='smallDarkHeader'>CAO: Air Image Devices</Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}
