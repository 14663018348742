import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { theme } from './theme'
import { Menu } from './components/Menu'
import { ButtonAppBar } from './components/ButtonAppBar'
import { Box, CircularProgress } from '@mui/material'
import { Suspense } from 'react'
import { CityConnectDevices } from './components/cityConnectDevices/CityConnectDevices'
import { CityConnectFirmwares } from './components/cityConnectFirmwares/CityConnectFirmwares'
import { CaoDevices } from './components/caoDevices/CaoDevices'
import { useWindowSize } from 'usehooks-ts'

// Needed to avoid white space when switching between portrait and landscape
// See e.g. https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
function setDocHeight(height: number) {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${height}px`)
}

function App() {
  const { height } = useWindowSize()
  setDocHeight(height)

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100vh',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <ButtonAppBar />
        <Router>
          <Suspense fallback={<CircularProgress />}>
            <Menu
              cityConnectDevicesRoute='/'
              cityConnectFirmwaresRoute='/firmwares'
              airImageDevicesRoute='/airimagedevices'
            />
            <Box
              sx={{
                width: '100%',
                overflow: 'auto',
                paddingTop: '100px',
              }}
            >
              <Routes>
                <Route path='/' element={<CityConnectDevices />} />
                <Route path='/firmwares' element={<CityConnectFirmwares />} />
                <Route path='/airimagedevices' element={<CaoDevices />} />
              </Routes>
            </Box>
          </Suspense>
        </Router>
      </Box>
    </ThemeProvider>
  )
}

export default App
