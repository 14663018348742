import { FirmwareDownloadBlobInfo } from '../modules/firmwareInfo'
import { DashboardInfo } from '../modules/externalDashboard'

export const CITY_CONNECT_PRODUCT = 'cityconnect'
export const CITY_CONNECT_DATABASE = 'CityConnect'
export const CITY_CONNECT_MEASUREMENTS_CONTAINER = 'Measurements'

export function isCityConnectProduct(product: string): product is typeof CITY_CONNECT_PRODUCT {
  return product === CITY_CONNECT_PRODUCT
}

type CityConnectIdFromDevice = {
  id: string
}

type CityConnectDeviceId = {
  deviceId: string
}

type CityConnectData = {
  utc: number
  airquality: number
  pm1: number
  pm2_5: number
  pm10: number
  pm1_num: number
  temp: number
  hum: number
  pdiff: number
  voci: number
  co2: number
  rpm: number
}

export type CityConnectRawDeviceData = CityConnectData & CityConnectIdFromDevice

export type CityConnectDeviceData = CityConnectData & CityConnectDeviceId

type IotHubConnection = {
  hostname: string
  key: string
}

type CityConnectDesiredTwinSettings = {
  firmware?: FirmwareDownloadBlobInfo
  dashboard?: DashboardInfo
  iotHub?: IotHubConnection
}

export type CityConnectDesiredTwinProperties = {
  settings?: CityConnectDesiredTwinSettings
}

export type CityConnectReportedTwinProperties = {
  currentFirmwareVersion?: string
  productType?: string
  language?: string
  ac?: string
  lat?: number
  lon?: number
}
