import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { getFullUrl } from './getFullUrl'

export async function uploadFirmware(
  token: string,
  product: typeof CITY_CONNECT_PRODUCT,
  version: string,
  file: File,
): Promise<boolean> {
  try {
    // 👇 Uploading the file using the fetch API to the server
    const result = await fetch(getFullUrl(`/api/firmwares/${product}/${version}`), {
      method: 'POST',
      body: file,
      // 👇 Set headers manually for single file upload
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/octet-stream',
        'content-length': `${file.size}`,
      },
    })

    if (!result.ok) {
      return false
    }

    const response = await result.json()
    console.log(response)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}
