import {
  Box,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../Login'
import { CityConnectDevice } from './CityConnectDevice'
import { CommonTableRow } from '../common/CommonTableRow'
import { CITY_CONNECT_PRODUCT } from '../../common-code-react/common-types/products/cityConnect'
import { DeviceReportInfo } from '../../common-code-react/common-types/device/deviceReport'
import { useDeviceReportInfos } from '../../hooks/useDeviceReportInfos'
import { useFirmwareInfos } from '../../hooks/useFirmwareInfos'
import { updateConstants } from '../../utils/updateConstants'
import { ButtonWithDialog } from '../common/ButtonWithDialog'

export function CityConnectDevices() {
  const loginContext = useContext(LoginContext)

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [searchString, setSearchString] = useState<string>('')
  const [filteredDevices, setFilteredDevices] = useState<DeviceReportInfo[]>([])
  const [updatingConstants, setUpdatingConstants] = useState(false)

  const { data: deviceReportInfos, error: deviceReportInfosError } = useDeviceReportInfos(
    loginContext.accessToken,
    CITY_CONNECT_PRODUCT,
  )

  const { data: firmwareInfos, error: firmwareInfosError } = useFirmwareInfos(
    loginContext.accessToken,
    CITY_CONNECT_PRODUCT,
  )

  useEffect(() => {
    if (searchString.length > 0 && deviceReportInfos && deviceReportInfos.length > 0) {
      const filtered = deviceReportInfos.filter((deviceInfo) => deviceInfo.deviceId.includes(searchString))
      setFilteredDevices(filtered)
    } else if (deviceReportInfos) {
      setFilteredDevices(deviceReportInfos)
    } else {
      setFilteredDevices([])
    }
  }, [searchString, deviceReportInfos])

  useEffect(() => {
    setPage(0)
  }, [filteredDevices])

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  async function runUpdateConstants() {
    if (loginContext.accessToken) {
      setUpdatingConstants(true)
      await updateConstants(loginContext.accessToken, CITY_CONNECT_PRODUCT)
      setUpdatingConstants(false)
    }
  }

  return (
    <>
      {deviceReportInfos && firmwareInfos && (
        <Container maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'start',
              justifyContent: 'space-between',
              maxWidth: 1350,
            }}
          >
            <TextField
              id='standard-basic'
              label='Search'
              variant='outlined'
              onChange={handleChangeSearch}
              size='small'
            />
            {!updatingConstants && (
              <ButtonWithDialog
                buttonText='Update constants'
                buttonTooltip='Update all constants in the backend'
                dialogTitle='Confim action'
                dialogContent='This is only needed after changing a global constant like ULR. Do you still want to proceed?'
                dialogConfirmText='Yes'
                dialogConfirmAction={() => runUpdateConstants()}
              />
            )}
            {updatingConstants && <CircularProgress />}
          </Box>
          {!updatingConstants && (
            <TableContainer sx={{ maxWidth: 1350 }}>
              <Table aria-label='City-M Devices' stickyHeader>
                <TableHead>
                  <CommonTableRow
                    isHeader
                    columns={['Device ID', 'Code', 'URL', '', '', 'Desired Firmware', '', 'Reported Firmware']}
                  />
                </TableHead>
                <TableBody>
                  {filteredDevices
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((deviceReportInfo, index) => (
                      <CityConnectDevice
                        key={index}
                        deviceReportInfo={deviceReportInfo}
                        firmwareInfos={firmwareInfos}
                      />
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                sx={{ marginTop: '20px' }}
                component='div'
                count={filteredDevices.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </Container>
      )}

      {(deviceReportInfosError || firmwareInfosError) && (
        <Container
          sx={{
            fontSize: 30,
          }}
        >
          Failed to load data.
        </Container>
      )}

      {!deviceReportInfos && !deviceReportInfosError && !firmwareInfos && !firmwareInfosError && (
        <Container>
          <CircularProgress />
        </Container>
      )}
    </>
  )
}
