import { ONE_DAY } from '../misc/timeConstants'

export const CAO_PRODUCT = 'cao'
export const CAO_DATABASE = 'CAO'

export const CACHE_TIME_FOR_CAO_DEVICE_REPORTS = ONE_DAY

export function isCaoProduct(product: string): product is typeof CAO_PRODUCT {
  return product === CAO_PRODUCT
}

type DataObject = {
  description: string
  unit: string
  timestamp: number
  value: number
}

export type CaoDeviceData = {
  sensorId: number
  name: string
  description?: string
  internalName?: string
  online?: DataObject
  pm1?: DataObject
  pm1count?: DataObject
  'pm2.5'?: DataObject
  pm10?: DataObject
  temperature?: DataObject
  latitude?: DataObject
  longitude?: DataObject
  rh?: DataObject
}
