import {
  Box,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { CommonTableRow } from '../common/CommonTableRow'
import { CaoDevice } from './CaoDevice'
import { useCaoDeviceDatas } from '../../common-code-react/hooks/useCaoDeviceDatas'
import { CaoDeviceData } from '../../common-code-react/common-types/products/cao'

export function CaoDevices() {
  const { data: caoDeviceDatas, error: caoDeviceDatasError } = useCaoDeviceDatas(
    process.env.REACT_APP_CAO_SENSOR_API || '',
    process.env.REACT_APP_CAO_SENSOR_API_KEY || '',
  )

  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [searchString, setSearchString] = useState<string>('')
  const [filteredDevices, setFilteredDevices] = useState<CaoDeviceData[]>([])

  useEffect(() => {
    if (searchString.length > 0 && caoDeviceDatas && caoDeviceDatas.length > 0) {
      const filtered = caoDeviceDatas.filter((device) => device.sensorId.toString().includes(searchString))
      setFilteredDevices(filtered)
    } else if (caoDeviceDatas) {
      setFilteredDevices(caoDeviceDatas)
    } else {
      setFilteredDevices([])
    }
  }, [searchString, caoDeviceDatas])

  useEffect(() => {
    setPage(0)
  }, [filteredDevices])

  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  return (
    <>
      {caoDeviceDatas && (
        <Container maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'start',
              justifyContent: 'space-between',
              maxWidth: 1350,
            }}
          >
            <TextField
              id='standard-basic'
              label='Search'
              variant='outlined'
              onChange={handleChangeSearch}
              size='small'
            />
          </Box>
          <TableContainer sx={{ maxWidth: 1800 }}>
            <Table aria-label='City-M Devices' stickyHeader>
              <TableHead>
                <CommonTableRow
                  isHeader
                  columns={[
                    'Device ID',
                    'URL',
                    'name',
                    'Override name',
                    'Device location',
                    'Override location',
                    'Hidden',
                    '',
                  ]}
                />
              </TableHead>
              <TableBody>
                {filteredDevices.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((caoDeviceData, index) => (
                  <CaoDevice key={index} caoDeviceData={caoDeviceData} />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              sx={{ marginTop: '20px' }}
              component='div'
              count={filteredDevices.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Container>
      )}

      {caoDeviceDatasError && (
        <Container
          sx={{
            fontSize: 30,
          }}
        >
          Failed to load data.
        </Container>
      )}

      {!caoDeviceDatas && !caoDeviceDatasError && (
        <Container>
          <CircularProgress />
        </Container>
      )}
    </>
  )
}
