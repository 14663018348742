import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export function ButtonAppBar() {
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant='largeBrightHeader' color={'white'} component='div' sx={{ flexGrow: 1 }}>
            Camfil - Internal IoT Portal
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
