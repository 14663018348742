import { CircularProgress, Select, MenuItem, Box, Typography, Stack } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../Login'
import { ButtonWithDialog } from '../common/ButtonWithDialog'
import { CITY_CONNECT_PRODUCT } from '../../common-code-react/common-types/products/cityConnect'
import { useProductInfo } from '../../hooks/useProductInfo'
import { updateProductInfo } from '../../utils/updateProductInfo'

const NO_FIRMWARE_VALUE = '[NOT-SET]'

interface Props {
  firmwares: string[]
}

export function DefaultFirmware({ firmwares }: Props) {
  const loginContext = useContext(LoginContext)

  const [chosenDefaultFirmware, setChosenDefaultFirmware] = useState<string>(NO_FIRMWARE_VALUE)

  const {
    data: productInfo,
    error: productInfoError,
    mutate: productInfoMutate,
  } = useProductInfo(loginContext.accessToken, CITY_CONNECT_PRODUCT)

  async function updateDefaultFirmwareVersion() {
    const result = await updateProductInfo(loginContext.accessToken, CITY_CONNECT_PRODUCT, {
      product: CITY_CONNECT_PRODUCT,
      defaultFirmwareVersion: chosenDefaultFirmware !== NO_FIRMWARE_VALUE ? chosenDefaultFirmware : undefined,
    })
    if (result) {
      await productInfoMutate(
        {
          product: CITY_CONNECT_PRODUCT,
          defaultFirmwareVersion: chosenDefaultFirmware !== NO_FIRMWARE_VALUE ? chosenDefaultFirmware : undefined,
        },
        {
          revalidate: false,
        },
      )
    }
  }

  useEffect(() => {
    if (productInfo && productInfo.defaultFirmwareVersion) {
      setChosenDefaultFirmware(productInfo.defaultFirmwareVersion)
    } else {
      setChosenDefaultFirmware(NO_FIRMWARE_VALUE)
    }
  }, [productInfo])

  return (
    <>
      {!productInfo && !productInfoError && <CircularProgress />}

      {productInfo && (
        <Stack sx={{ display: 'flex', flexDirection: 'column', maxWidth: 600 }} spacing={1}>
          <Typography variant='mediumDarkHeader'>Default for new devices</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Select
              labelId='select-firmware-label'
              id='select-firmware'
              value={chosenDefaultFirmware}
              label='Age'
              onChange={(e) => setChosenDefaultFirmware(e.target.value)}
            >
              <MenuItem value={NO_FIRMWARE_VALUE}>{NO_FIRMWARE_VALUE}</MenuItem>
              {firmwares.map((firmware) => (
                <MenuItem key={firmware} value={firmware}>
                  {firmware}
                </MenuItem>
              ))}
            </Select>
            <ButtonWithDialog
              buttonText='Update default firmware'
              buttonTooltip={'Confirm new default firmware version'}
              isDisabled={
                (productInfo?.defaultFirmwareVersion && chosenDefaultFirmware === productInfo.defaultFirmwareVersion) ||
                (!productInfo?.defaultFirmwareVersion && chosenDefaultFirmware === NO_FIRMWARE_VALUE)
              }
              dialogTitle='Confim action'
              dialogContent='Confirm new default firmware value?'
              dialogConfirmText='Confirm'
              dialogConfirmAction={(): void => {
                updateDefaultFirmwareVersion()
              }}
            />
          </Box>
        </Stack>
      )}
    </>
  )
}
