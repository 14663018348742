import { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { DeviceSettingsInfo, ValidDeviceSettingsProduct } from '../common-code-react/common-types/device/deviceSettings'
import { fetcher } from '../common-code-react/utils/fetcher'
import { getFullUrl } from '../utils/getFullUrl'

export function useDeviceSettingsInfo(
  token: string | null,
  product: ValidDeviceSettingsProduct,
  deviceId: string,
): SWRResponse<DeviceSettingsInfo> {
  const url = getFullUrl(`/api/devices/settings/${product}/${deviceId}`)
  const response = token
    ? useSWRImmutable<DeviceSettingsInfo>(url, fetcher(token), { shouldRetryOnError: false })
    : useSWRImmutable<DeviceSettingsInfo>(null)

  return response
}
