import useSWR, { SWRResponse } from 'swr'
import { FirmwareInfo } from '../common-code-react/common-types/modules/firmwareInfo'
import { CITY_CONNECT_PRODUCT } from '../common-code-react/common-types/products/cityConnect'
import { fetcher } from '../common-code-react/utils/fetcher'
import { getFullUrl } from '../utils/getFullUrl'

export function useFirmwareInfos(
  token: string | null,
  product: typeof CITY_CONNECT_PRODUCT,
): SWRResponse<FirmwareInfo[]> {
  const url = getFullUrl(`/api/firmwares/${product}`)
  return token ? useSWR(url, fetcher(token)) : useSWR(null)
}
