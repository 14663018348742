import { useContext, useEffect, useState } from 'react'
import { Box, IconButton, Link, Stack, Switch, TextField, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CommonTableRow } from '../common/CommonTableRow'
import { ButtonWithDialog } from '../common/ButtonWithDialog'
import { updateDeviceSettingsInfo } from '../../utils/updateDeviceSettingsInfo'
import { LoginContext } from '../../Login'
import { GeoLocationInfo } from '../../common-code-react/common-types/modules/geoLocationInfo'
import { HiddenInfo } from '../../common-code-react/common-types/modules/hiddenInfo'
import { CaoDeviceData, CAO_PRODUCT } from '../../common-code-react/common-types/products/cao'
import { useDeviceSettingsInfo } from '../../hooks/useDeviceSettingsInfo'

interface Props {
  caoDeviceData: CaoDeviceData
}

export function CaoDevice({ caoDeviceData }: Props) {
  const loginContext = useContext(LoginContext)

  const { data: deviceSettingsInfo, mutate: deviceSettingsInfoMutate } = useDeviceSettingsInfo(
    loginContext.accessToken,
    CAO_PRODUCT,
    `${caoDeviceData.sensorId}`,
  )

  const [overrideLatitude, setOverrideLatitude] = useState<string | null>(null)
  const [overrideLongitude, setOverrideLongitude] = useState<string | null>(null)

  const [overrideName, setOverrideName] = useState<string>('')

  const [hiddenValue, setHiddenValue] = useState<boolean>(false)

  useEffect(() => {
    console.log('useEffect deviceInfo')
    setOverrideLatitude(
      deviceSettingsInfo?.deviceSettings?.geoLocationInfo?.lat
        ? deviceSettingsInfo.deviceSettings.geoLocationInfo.lat.toString()
        : null,
    )
    setOverrideLongitude(
      deviceSettingsInfo?.deviceSettings?.geoLocationInfo?.lng
        ? deviceSettingsInfo.deviceSettings.geoLocationInfo.lng.toString()
        : null,
    )
    setOverrideName(deviceSettingsInfo?.deviceSettings?.nameInfo?.name || '')
  }, [deviceSettingsInfo])

  const dashboardUrl = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Link
        href={`${process.env.REACT_APP_CAO_DASHBOARD_URL}?id=${caoDeviceData.sensorId}`}
        target='_blank'
        underline='hover'
        sx={{ fontStyle: 'italic' }}
      >
        {`${process.env.REACT_APP_CAO_DASHBOARD_URL}?id=${caoDeviceData.sensorId}`}
      </Link>
      <IconButton
        onClick={() => {
          navigator.clipboard.writeText(`${process.env.REACT_APP_CAO_DASHBOARD_URL}?id=${caoDeviceData.sensorId}`)
        }}
      >
        <ContentCopyIcon />
      </IconButton>
    </Box>
  )

  const nameComponent = <Typography>{caoDeviceData.name}</Typography>

  const overrideNameComponent = (
    <TextField
      id='overrideName'
      label='Override Name'
      variant='standard'
      value={overrideName}
      onChange={(e) => {
        setOverrideName(e.target.value)
      }}
    />
  )

  const deviceLocation = (
    <>
      {caoDeviceData.latitude && caoDeviceData.longitude && (
        <Stack>
          <Typography>Latitude: {caoDeviceData.latitude.value}</Typography>
          <Typography>Longitude: {caoDeviceData.longitude.value}</Typography>
        </Stack>
      )}
    </>
  )

  const overrideLocation = (
    <Stack>
      <TextField
        id='override-latitude'
        label='Latitude'
        variant='standard'
        value={overrideLatitude || ''}
        onChange={(e) => {
          if (e.target.value.length === 0) {
            setOverrideLatitude(null)
          } else {
            setOverrideLatitude(e.target.value)
          }
        }}
      />
      <TextField
        id='override-longitude'
        label='Longitude'
        variant='standard'
        value={overrideLongitude || ''}
        onChange={(e) => {
          if (e.target.value.length === 0) {
            setOverrideLongitude(null)
          } else {
            setOverrideLongitude(e.target.value)
          }
        }}
      />
    </Stack>
  )

  const deviceHidden = <>{<Switch checked={hiddenValue} onChange={() => setHiddenValue(!hiddenValue)} />}</>

  async function updateDevice(
    overrideLocation: GeoLocationInfo | undefined,
    overrideName: string | undefined,
    hiddenInfo: HiddenInfo | undefined,
  ): Promise<void> {
    const deviceSettings = {
      geoLocationInfo: overrideLocation,
      nameInfo: overrideName
        ? {
            name: overrideName,
          }
        : undefined,
      hiddenInfo: hiddenInfo,
    }
    const deviceId = deviceSettingsInfo?.deviceId || `${caoDeviceData.sensorId}`
    const result = await updateDeviceSettingsInfo(loginContext.accessToken, CAO_PRODUCT, deviceId, deviceSettings)
    if (result) {
      deviceSettingsInfoMutate(
        {
          deviceId,
          product: CAO_PRODUCT,
          deviceSettings,
        },
        { revalidate: false },
      )
    }
  }

  const floatLatitude = overrideLatitude ? parseFloat(overrideLatitude) : null
  const latitudeValid = overrideLatitude !== null && floatLatitude !== null && !isNaN(floatLatitude)
  const overrideLatitudeChanged: boolean =
    (deviceSettingsInfo?.deviceSettings?.geoLocationInfo && overrideLatitude === null) ||
    (deviceSettingsInfo?.deviceSettings?.geoLocationInfo &&
      latitudeValid &&
      floatLatitude.toFixed(8) !== deviceSettingsInfo?.deviceSettings.geoLocationInfo.lat.toFixed(8)) ||
    (!deviceSettingsInfo?.deviceSettings?.geoLocationInfo && latitudeValid)

  const floatLongitude = overrideLongitude ? parseFloat(overrideLongitude) : null
  const longitudeValid = overrideLongitude !== null && floatLongitude !== null && !isNaN(floatLongitude)
  const overrideLongitudeChanged: boolean =
    (deviceSettingsInfo?.deviceSettings?.geoLocationInfo && overrideLongitude === null) ||
    (deviceSettingsInfo?.deviceSettings?.geoLocationInfo &&
      longitudeValid &&
      floatLongitude.toFixed(8) !== deviceSettingsInfo?.deviceSettings.geoLocationInfo.lng.toFixed(8)) ||
    (!deviceSettingsInfo?.deviceSettings?.geoLocationInfo && longitudeValid)

  const overrideNameChanged =
    (!deviceSettingsInfo?.deviceSettings?.nameInfo && overrideName !== '') ||
    (deviceSettingsInfo?.deviceSettings?.nameInfo && deviceSettingsInfo?.deviceSettings.nameInfo.name !== overrideName)

  const overrideLocationChanged: boolean =
    (overrideLatitudeChanged && overrideLongitudeChanged) ||
    ((overrideLatitudeChanged || overrideLongitudeChanged) && latitudeValid && longitudeValid)

  const hiddenValueChanged = (deviceSettingsInfo?.deviceSettings?.hiddenInfo?.hidden || false) !== hiddenValue

  const sendUpdates = (
    <ButtonWithDialog
      buttonText='Update device information'
      buttonTooltip={'Confirm new values'}
      isDisabled={!overrideNameChanged && !overrideLocationChanged && !hiddenValueChanged}
      dialogTitle='Confim action'
      dialogContent='Confirm new values?'
      dialogConfirmText='Confirm'
      dialogConfirmAction={() => {
        updateDevice(
          overrideLatitude && overrideLongitude
            ? { lat: parseFloat(overrideLatitude) || 0, lng: parseFloat(overrideLongitude) || 0 }
            : undefined,
          overrideName,
          hiddenValue ? { hidden: true } : undefined,
        )
      }}
    />
  )

  return (
    <CommonTableRow
      columns={[
        `${caoDeviceData.sensorId}`,
        dashboardUrl,
        nameComponent,
        overrideNameComponent,
        deviceLocation,
        overrideLocation,
        deviceHidden,
        sendUpdates,
      ]}
      noWrapRules={[true, false, true, true, true, true, true, true, false]}
    />
  )
}
