import useSWR, { SWRResponse } from 'swr'
import { DeviceReportInfo, ValidDeviceReportProduct } from '../common-code-react/common-types/device/deviceReport'
import { fetcher } from '../common-code-react/utils/fetcher'
import { getFullUrl } from '../utils/getFullUrl'

export function useDeviceReportInfos(
  token: string | null,
  product: ValidDeviceReportProduct,
): SWRResponse<DeviceReportInfo[]> {
  const url = getFullUrl(`/api/devices/report/${product}/`)
  return token ? useSWR<DeviceReportInfo[]>(url, fetcher(token)) : useSWR<DeviceReportInfo[]>(null)
}
